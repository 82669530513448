<template>
  <el-dialog
  :title="this.isUp== 1? '上传合同' : '查看合同'" :visible.sync="dialogVisible3"
   width="34%"
    :before-close="handleClose">
    <el-upload class="upload-demo" ref="AvatarUpload" action=""
      :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG, WebP,.pdf'" list-type="picture-card" :auto-upload="false"
      :file-list="fileList" :on-change="upLoadImg" multiple>
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <el-image ref="preview" style="height: 115px" class="el-upload-list__item-thumbnail" :src="file.url"
          :preview-src-list="innerTableList" alt="" />
        <span>{{ file.name }}</span>
        <span class="el-upload-list__item-actions">
          <span v-if="!file.isShow " class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="isUp == 1 ||file.isShow" class="el-upload-list__item-delete" @click="handleDownload(file)">
            <i class="el-icon-download"></i>
          </span>
          <span v-if="isUp == 1" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <!-- <el-dialog title="预览" :append-to-body="true" :visible.sync="BgColor">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog> -->
    <div slot="footer" class="dialog-footer" v-if="isUp == 1">
      <el-button style="margin-top: 20px" @click="back">取消</el-button>
      <el-button type="primary" @click="submit()"> 确定 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { uploadFile } from "@/api/fileApi";
// 图片下载
import { download } from "@/api/ChargeUp";
import { uploadOrderContract, selectOrderContract } from "@/api/projectManage";
export default {
  data() {
    return {
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      baseUrl: window.globalUrl.BASE_API,
      dialogVisible3: true,
      dialogImageUrl: "",
      // BgColor: false,
      // isShow: false,
      fileList: [],
      upImg: [],
      // xmbsm: "", //项目标识码
      // xmbh
      InitObj: {},
      innerTableList: [],
      //是否是上传按钮点击过来的
      isUp: "",
    };
  },
  watch: {
    fileList: {
      handler(newVal, oldVal) {
        // 检查每个文件对象的 name 属性
        // this.$nextTick(() => {
        // 在 DOM 更新之后执行回调函数
        newVal.forEach((file) => {
          if (file.name.toLowerCase().endsWith("pdf")) {
            // 修改符合条件的文件对象的 url 属性
            file.url =
              "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
            file.isShow = "true";
          }
          // });
          // console.log("fileList", this.fileList);
          // console.log("文件列表已更新");
          // console.log("新值:", newVal);
        });
        // 在这里可以根据实际需求执行相应操作
      },
      deep: true, // 深度监听数组内部元素的变化
    },
  },
  methods: {
    back() {
      this.$emit("closeDialog3", false);
    },
    handleClose() {
      this.$emit("closeDialog3", false);
    },
    //删除
    handleRemove(file, fileList) {
      //可用版本1
      // console.log(file, fileList);
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      this.fileList.splice(index, 1);
    },
    //放大
    handlePictureCardPreview(file) {
      let abc = [];
      this.fileList.forEach((its) => {
        if (!its.isShow) {
          abc.push(its.url);
        }
      });
      this.innerTableList = abc;
      this.$nextTick(() => {
        this.$refs.preview.clickHandler();
      });
    },
    //上传的图片数组
    getViewUrl(arr) {
      let list = [];
      // console.log(arr, "查看这个arr");
      arr.forEach((item) => {
        list.push({
          FilesName: item.name,
          imgUrl: item.url2,
          uid: item.uid,
        });
      });
      return JSON.stringify(list);
    },
    // 改变回显的样式
    getViewUrl1(arr) {
      arr.forEach(async (item) => {
        item.name = item.FilesName;
        item.url = this.baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl;
        item.url2 = item.imgUrl;
      });
      this.fileList = arr;
    },
    //回显
    async Init(obj, isUp) {
      this.InitObj = obj;
      this.isUp=isUp
      //1代表的是点击的查看事件，隐藏上传框
      if (isUp == 0) {
        this.$refs.AvatarUpload.$children[1].$el.style.display = 'none'
      }
      // console.log(obj, "查看传过来的值");
      // console.log(isUp, "查看isUp");
      // console.log(JSON.parse(JSON.stringify(obj)));
      // 如果status=1代表上传过

        let params = {
          orderId: obj.orderId,
        };
        let res = await selectOrderContract(params);
        this.getViewUrl1(JSON.parse(res.data.picUrl));

    },
    async upLoadImg(file, fileList) {
      let res = await this.upload(file, fileList);
    },
    // 上传
    async upload(file, fileList) {
      // console.log(fileList, "fileList");
      let param = new FormData();
      param.append("file", file.raw);
      let res = await uploadFile(param);
      file.url2 = res.data.url;
      // console.log("file", file);
      this.fileList = fileList;
    },
    //发布或编辑
    async submit() {
        let ImgUrl = this.getViewUrl(this.fileList);
        let params = {
          contractBaseInfoDtoList: [{ orderId: this.InitObj.orderId }],
          picUrl: ImgUrl,
        };
        let res = await uploadOrderContract(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.$emit("closeDialog3", false)
    },
    //下载
    async handleDownload(file) {
      console.log("下载");
      console.log(file);
      let itemList = file;
      let res = await download({
        ossFilePath:
          this.baseUrl + "admin/file/get?ossFilePath=" + itemList.url2,
      });
      if (res) {
        let blob = res;
        let ToName = itemList.name;
        const fileName = ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
