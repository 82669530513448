export const wytgColumn = [
  {
    prop: "orderType",
    label: "托管类型",
    width: 80,
    template: true,
  },
  {
    prop: "cropCode",
    label: "种养品种",
  },

  {
    prop: "landArea",
    label: "面积(亩)",
  },
  {
    prop: "estimatePrice",
    label: "托管价格(元)",
  },
  {
    prop: "orderState",
    label: "项目状态",
    width: 80,
    template: true
  },
  {
    prop: "createTime",
    label: "发布时间",
    width: 140,
    template: true
  },
  {
    prop: "Time",
    label: "托管时间",
    width: 140,
    template: true
  },
  {
    prop: "takeOverName",
    label: "接管人",
  },
  {
    prop: "operation",
    label: "操作",
    width: 280,
    template: true,
  },
]
export const wyjgColumn = [
  {
    prop: "serviceScope",
    label: "作物类型",
  },
  {
    prop: "operScope",
    label: "农事类型",
  },

  {
    prop: "intentionRegion",
    label: "意向区域 ",
  },
  {
    prop: "contactMan",
    label: "联系人",
    width: 90,
  },
  {
    prop: "phone",
    label: "联系电话",
    width: 100,
  },
  {
    prop: "createTime",
    label: "发布时间",
    width: 140,
  },
  {
    prop: "status",
    label: "状态",
    width: 70,
    template: true,
  },
  {
    prop: "operation",
    label: "操作",
    width: 190,
    template: true,
  },
]
export const yjgtdColumn = [
  {
    prop: "orderCode",
    label: "托管编号",
  },
  {
    prop: "areaName",
    label: "区域",
  },

  {
    prop: "orderType",
    label: "接管类型 ",
    template: true,
  },
  {
    prop: "estimatePrice",
    label: "价格",
  },
  {
    prop: "farmerName",
    label: "托管人",

  },
  {
    prop: "Time",
    label: "托管时间",
    template: true,
  },
  {
    prop: "orderState",
    label: "接管状态",
    template: true,
  },
  {
    prop: "operation",
    label: "操作",
    width: 300,
    template: true,
  },
]

export const sctgtdColumn = [
  {
    prop: "orderCode",
    label: "项目编号",
    template: true,
  },
  {
    prop: "areaName",
    label: "区域",
  },
  {
    prop: "orderType",
    label: "托管类型 ",
    width: 80,
    template: true,
  },
  {
    prop: "cropCode",
    width: 80,
    label: "作物",
  },
  {
    prop: "landArea",
    label: "面积",
    width: 80,
  },
  {
    prop: "estimatePrice",
    label: "价格",
    width: 80,
  },
  {
    prop: "orderState",
    label: "项目状态",
    width: 90,
    template: true,
  },
  {
    prop: "createTime",
    label: "发布时间",
    width: 90,
    template: true,
  },
  {
    prop: "Time",
    label: "托管时间",
    width: 200,
    template: true,
  },
  {
    prop: "operation",
    label: "操作",
    width: 120,
    template: true,
  },
]

export const jgtsColumn = [
  {
    prop: "companyName",
    label: "名称",
  },
  {
    prop: "intentionRegion",
    label: "意向区域",
  },
  {
    prop: "serviceArea",
    label: "可托管土地面积 ",
    width: 110,
  },
  {
    prop: "serviceAreaOlde",
    label: "已托管土地面积",
    width: 110,
  },
  {
    prop: "serviceType",
    label: "托管类型",
    template: true,
  },
  {
    prop: "serviceScope",
    label: "托管种养品种",
  },
  {
    prop: "contactMan",
    label: "联系人",
    width: 80,
  },
  {
    prop: "address",
    label: "地址",
    width: 120,
  },
  {
    prop: "operation",
    label: "操作",
    width: 110,
    template: true,
  },
]

export const tgtsColumn = [
  {
    prop: "orderType",
    label: "托管类型",
    width: 80,
    template: true,
  },
  {
    prop: "orderContent",
    label: "托管农事类型",
  },
  {
    prop: "cropCode",
    label: "种养品种 ",
    width: 110,
  },
  {
    prop: "landArea",
    label: "面积（亩）",
    width: 70,
  },
  {
    prop: "estimatePrice",
    label: "总价（元）",
    width: 70,
  },
  {
    prop: "farmerName",
    label: "托管人",
    width: 70,
  },
  {
    prop: "Time",
    label: "托管时间",
    width: 160,
    template: true,
  },
  {
    prop: "areaName",
    label: "地址",
  },
  {
    prop: "pushCompanyName",
    label: "已推送农服商",
  },
  {
    prop: "operation",
    label: "操作",
    width: 110,
    template: true,
  },
]

export const PeasantCollectivePurchaseSignUp = [
  {
    prop: "procurementCode",
    label: "项目编号",
  },
  {
    prop: "title",
    label: "标题",
    template: true,
  },
  {
    prop: "applyCode",
    label: "订单编号 ",

  },
  {
    prop: "applyTime",
    label: "报名时间",

  },
  {
    prop: "prepayment",
    label: "预交费(元)",

  },
  {
    prop: "status",
    label: "状态",

    template: true,
  },

  {
    prop: "operation",
    label: "操作",
    width: 250,
    template: true,
  },
]
export const RegisterToView = [
  {
    prop: "brand",
    label: "品牌",

  },
  {
    prop: "model",
    label: "型号",
  },
  {
    prop: "specifications",
    label: "规格 ",

  },
  {
    prop: "producer",
    label: "生产商",

  },
  {
    prop: "guidePrice",
    label: "最高限价(元)",
    template: true,
  },

  {
    prop: "unit",
    label: "采购单位",


  },
  {
    prop: "otherIndicator",
    label: "其他指标",


  },
  {
    prop: "quantity",
    label: "报名数量",
    template: true,
  },

]


export const scdnfsColumn = [
  {
    prop: "companyName",
    label: "农夫商名称",
    // template: true,
  },
  {
    prop: "serviceScope",
    label: "作物类型 ",
    template: true,
  },
  {
    prop: "serviceType",
    label: "服务类型",
    template: true,
  },
  {
    prop: "operScope",
    label: "环节",
    // template: true,
  },
  {
    prop: "intentionRegion",
    label: "意向区域",
  },
  {
    prop: "serviceArea",
    label: "可接管面积",
  },
  {
    prop: "serviceAreaOlde",
    label: "已接管面积",
  },
  {
    prop: "createTime",
    label: "发布时间",

    // template: true,
  },
  {
    prop: "operation",
    label: "操作",
    width: 120,
    template: true,
  },
]

